import React, { useContext } from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { LoginContext } from "../../context/LoginContext";
import "./stylesMenu.scss";

const ModalMenu = (props) => {
  const { videos } = useContext(LoginContext);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-menu"
    >
      <Modal.Header>
        <button
          alt="Previous"
          onClick={props.onHide}
          className="btn btn-druki p-0"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="26"
            fill="currentColor"
            class="bi bi-x-circle-fill"
            viewBox="0 0 16 16"
          >
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
          </svg>
        </button>
      </Modal.Header>
      <Modal.Body>
        <Link to="/" className="item-menu">
          Inicio
        </Link>
        <br />
        {videos.map((video) => {
          return (
            <>
              <Link to={"/video/" + video.id} className="item-menu" replace>
                {video.name}
              </Link>
              <br />
            </>
          );
        })}
        <Link to="/contact" className="item-menu">
          Contacto
        </Link>
      </Modal.Body>
    </Modal>
  );
};

export default ModalMenu;
