import React from "react";
import "./styles.scss";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <section className="container__footer">
        Copyright © {currentYear} | Powered by&nbsp;<a href="https://filevel.com/" target="_blank" rel="noopener noreferrer">Filevel Co. ®</a>
      </section>
    </>
  );
};

export default Footer;
