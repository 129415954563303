import { tokenizedPost } from '../helpers/post';
import swal from 'sweetalert';

const postUser = async ({ values, users, setUsers, closeModal, setLoading, setValidated, jwt }) => {
    setLoading(true);
    const response = await tokenizedPost('register', values, jwt.access_token);

    if (response.data.status === 201) {
        setUsers([...users,response.data.user]);
        swal({
            icon: 'success',
            text: response.data.message,
            button: 'Aceptar'
        });
        closeModal();
        return setLoading(false);
    }
    // errores
    const { message } = response.data;
    let error = '';
    if (message instanceof Object) {
        error = Object.values(message)
            .map(item => item.join(', ')).join(', ');
    } else {
        error = message;
    }
    setValidated(error);
    return setLoading(false);
}

export default postUser;