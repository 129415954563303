/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import "./styles.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode } from "swiper";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

const Carousel = ({ modalService1, modalService2, modalService3, modalService4, modalService5, modalService6 }) => {
  return (
    <div className="container-carousel">
      {/* <img
        src={`${
          process.env.REACT_APP_FRONTEND + "assets/image/arrow_right.svg"
        }`}
        alt="Next"
        className="next-services btn btn-druki"
      /> */}
      <Swiper
        className="mySwiper-mobile"
        slidesPerView={1.5}
        freeMode={true}
        modules={[FreeMode]}
        
      >
        <SwiperSlide>
          {" "}
          <div className="item-1">
            <p>1. Contacto</p>
            <button onClick={() => modalService1(true)} className="btn btn-druki-carrousel">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-send-fill mr-2" viewBox="0 0 16 16">
                <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083l6-15Zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471-.47 1.178Z"/>
              </svg>
            Escribénos
            </button>
          
          </div>
        </SwiperSlide>
        <SwiperSlide>
          {" "}
          <div className="item-2">
          <p>2. Quiénes Somos</p>
            <button onClick={() => modalService2(true)} className="btn btn-druki-carrousel">
              Ver más
            </button>
           
          </div>
        </SwiperSlide>
       <SwiperSlide>
          {" "}
          <div className="item-3">
          <p>3. Diseño</p>
            <button onClick={() => modalService3(true)} className="btn btn-druki-carrousel">
              Ver más
            </button>
           
          </div>
        </SwiperSlide>
       <SwiperSlide>
          {" "}
          <div className="item-4">
            <p>4. Construcción</p>
            <button onClick={() => modalService4(true)} className="btn btn-druki-carrousel">
              Ver más
            </button>
          </div>
        </SwiperSlide>
      <SwiperSlide>
          {" "}
          <div className="item-5">
            <p>5. Mantemiento</p>
            <button onClick={() => modalService5(true)} className="btn btn-druki-carrousel">
              Ver más
            </button>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          {" "}
          <div className="item-6">
            <p>6. Insumos</p>
            <button onClick={() => modalService6(true)} className="btn btn-druki-carrousel">
              Ver más
            </button>
          </div>
        </SwiperSlide> 
      </Swiper>

      {/* Slider XL  */}

      <Swiper
        className="mySwiper-desktop"
        slidesPerView={3}
        freeMode={true}
        modules={[FreeMode]}
       
      >
         <SwiperSlide>
          {" "}
          <div className="item-1">
            <p>1. Contacto</p>
            <button onClick={() => modalService1(true)} className="btn btn-druki-carrousel">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-send-fill mr-2" viewBox="0 0 16 16">
                <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083l6-15Zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471-.47 1.178Z"/>
              </svg>
            Escribénos
            </button>
          
          </div>
        </SwiperSlide>
        <SwiperSlide>
          {" "}
          <div className="item-2">
          <p>2. Quiénes Somos</p>
            <button onClick={() => modalService2(true)} className="btn btn-druki-carrousel">
              Ver más
            </button>
           
          </div>
        </SwiperSlide>
       <SwiperSlide>
          {" "}
          <div className="item-3">
          <p>3. Diseño</p>
            <button onClick={() => modalService3(true)} className="btn btn-druki-carrousel">
              Ver más
            </button>
           
          </div>
        </SwiperSlide>
       <SwiperSlide>
          {" "}
          <div className="item-4">
            <p>4. Construcción</p>
            <button onClick={() => modalService4(true)} className="btn btn-druki-carrousel">
              Ver más
            </button>
          </div>
        </SwiperSlide>
      <SwiperSlide>
          {" "}
          <div className="item-5">
            <p>5. Mantemiento</p>
            <button onClick={() => modalService5(true)} className="btn btn-druki-carrousel">
              Ver más
            </button>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          {" "}
          <div className="item-6">
            <p>6. Insumos</p>
            <button onClick={() => modalService6(true)} className="btn btn-druki-carrousel">
              Ver más
            </button>
          </div>
        </SwiperSlide> 
      </Swiper>
        

    </div>
  );
};

export default Carousel;
