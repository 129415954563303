import {
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';


export function useLoader() {
    const [loader, setLoader] = useState(false);
    const isComponentMounted = useRef(true);
    
    useEffect(() => {
      isComponentMounted.current = true;
      return () => {
        isComponentMounted.current = false;
      }
    }, [isComponentMounted]);
    

    const setLoading = useCallback(
        (state) => {
            if (isComponentMounted.current) {
                setLoader(state);
            }
        },
        [isComponentMounted]
    );

    return {
        loader,
        setLoading,
    };
};