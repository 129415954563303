import axios from 'axios';

const tokenizedGet = (URL, token) => {
    return axios.get(`${process.env.REACT_APP_API}/${URL}`, { headers: { "Authorization": `Bearer ${token}` } });
}

const tokenizedGetId = (URL, id, token) => {
    return axios.get(`${process.env.REACT_APP_API}/${URL}` + id, { headers: { "Authorization": `Bearer ${token}` } });
}

const getIdWithoutToken = (URL, id) => {
    return axios.get(`${process.env.REACT_APP_API}/${URL}` + id);
}

const getWithoutToken = (URL) => {
    return axios.get(`${process.env.REACT_APP_API}/${URL}`);
}

export {
    tokenizedGet,
    tokenizedGetId,
    getIdWithoutToken,
    getWithoutToken
}