import React from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./styles.scss";

const ModalServices = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        {/* <Modal.Title id="contained-modal-title-vcenter">
          {props.number}
        </Modal.Title> */}
        <button
          alt="Previous"
          onClick={props.onHide}
          className="btn btn-druki p-0"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="26"
            fill="currentColor"
            class="bi bi-x-circle-fill"
            viewBox="0 0 16 16"
          >
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
          </svg>
        </button>
        {/* <img
          src={`${
            process.env.REACT_APP_FRONTEND + "assets/image/close_modal.svg"
          }`}
         
        /> */}
      </Modal.Header>
      <Modal.Body>
        <h4>{props.title}</h4>
        <div>{props.description ? props.description : ""}</div>
        <div className="content__modal__btn">
          <Link
            to={"/contact"}
            id={props.number}
            className="contact btn btn-druki"
          >
            Contactar
          </Link>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalServices;
