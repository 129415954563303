import { tokenizedPut } from '../helpers/put';
import swal from 'sweetalert';

const putUser = async ({ values, users, setUsers, closeModal, setLoading, setValidated, jwt }) => {
    setLoading(true);
    const response = await tokenizedPut('updateUser/', values.id, values, jwt.access_token);

    if (response.data.status === 201) {
        const new_array = users.map(user => {
            if (values.id === user.id) {
                return values;
            }
            return user;
        });
        setUsers(new_array);
        swal({
            icon: 'success',
            text: response.data.message,
            button: 'Aceptar'
        });
        closeModal();
        return setLoading(false);
    }
    // errores
    const { message } = response.data;
    let error = '';
    if (message instanceof Object) {
        error = Object.values(message)
            .map(item => item.join(', ')).join(', ');
    } else {
        error = message;
    }
    setValidated(error);
    return setLoading(false);
}

export default putUser;