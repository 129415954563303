import React, { useState } from "react";
import ModalMenu from "../shared/ModalMenu";
import "./styles.scss";

const Menu = () => {
  const [modalMenu, setModalMenu] = useState(false);
  return (
    <>
      <img
        src={`${process.env.REACT_APP_FRONTEND + "assets/image/menu.png"}`}
        alt="Logo Druky"
        className="logo-menu"
        onClick={() => setModalMenu(true)}
      />
      <ModalMenu show={modalMenu} onHide={() => setModalMenu(false)} />
    </>
  );
};

export default Menu;
