import axios from 'axios';

const tokenizedPost = (URL, data, token) => {
    return axios.post(`${process.env.REACT_APP_API}/${URL}`, data, { headers: { "Authorization": `Bearer ${token}` } });
}

const tokenizedPostData = (URL, data, token) => {
    return axios.post(`${process.env.REACT_APP_API}/${URL}`, data, { headers: { 'Content-Type': 'multipart/form-data', "Authorization": `Bearer ${token}` } });
}


const postWithoutToken = (URL, data) => {
    return axios.post(`${process.env.REACT_APP_API}/${URL}`, data);
}

export {
    tokenizedPost,
    tokenizedPostData,
    postWithoutToken
}