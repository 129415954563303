import React, { useContext, useState } from 'react'
import { Button, Form, Spinner } from 'react-bootstrap';
import putUser from '../../services/User/putUser';
import postUser from '../../services/User/postUser';
import { UserContext } from "../../context/UserContext";
import { useLoader } from '../../hooks/useLoader';
import { LoginContext } from '../../context/LoginContext';

const initialValues = {
    id: "",
    name: "",
    lastname: "",
    birth_date: "",
    email: "",
    password: ""
};

const FormUser = ({ closeModal, optionForm }) => {

    const { users, setUsers, selectedUser } = useContext(UserContext);
    const { jwt } = useContext(LoginContext);

    const { loader, setLoading } = useLoader();

    const [validated, setValidated] = useState('');

    const [values, setValues] = useState(selectedUser || initialValues);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setValues(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const createUser = () => {
        postUser({
            values,
            users,
            setUsers,
            closeModal,
            setLoading,
            setValidated,
            jwt
        });
    };

    const updateUser = () => {
        putUser({
            values,
            users,
            setUsers,
            closeModal,
            setLoading,
            setValidated,
            jwt
        });
    };

    const submitForm = () => {
        (optionForm) ? updateUser() : createUser();
    };

    return (
        <Form
            className="container-fluid p-4"
            onSubmit={e => {
                e.preventDefault();
                submitForm();
            }}
        >
            {loader &&
                <div className="container-loader-modal">
                    <Spinner animation="grow" />
                </div>
            }
            {!loader &&
                <>
                    <Form.Group className="mb-3">
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control required type="text" placeholder="Ingresar nombre" name="name" onChange={handleChange} value={values.name} />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Apellido</Form.Label>
                        <Form.Control required type="text" placeholder="Ingresar apellido" name="lastname" onChange={handleChange} value={values.lastname} />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Fecha de nacimiento</Form.Label>
                        <Form.Control required type="date" onChange={handleChange} name="birth_date" value={values.birth_date} />
                    </Form.Group>

                    {!optionForm &&
                        <>
                            <Form.Group className="mb-3">
                                <Form.Label>Email</Form.Label>
                                <Form.Control required type="email" placeholder="Ingresar email" name="email" onChange={handleChange} value={values.email} />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Contraseña</Form.Label>
                                <Form.Control required type="password" placeholder="Ingresar contraseña" name="password" onChange={handleChange} value={values.password} />
                            </Form.Group>
                        </>
                    }

                    {
                        (validated !== '') &&
                        <div className="text-danger">
                            {validated}
                        </div>
                    }
                    <br />
                    <Button variant="primary" type="submit" className="btn btn-default btn-success mt-3 float-right">
                        Confirmar
                    </Button>
                    <Button onClick={closeModal} variant="primary" type="submit" className="btn btn-danger mt-3 mr-2 float-right">
                        Cancelar
                    </Button>
                </>
            }
        </Form>
    );
}

export default FormUser;
