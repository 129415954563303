import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomeScreen from "../views/Home";
import VideoScreen from "../views/Video";
import LandingScreen from "../views/Landing";
import LoginScreen from "../views/Login";
import ErrorScreen from "../views/Error";
import LoginRouter from "./LoginRouter";
import PrivateRouter from "./PrivateRouter";
import DetailVideoScreen from "../views/Landing/DetailVideoScreen";
import ContactScreen from "../views/Contact";

const AppRouter = () => {
    return (
        <Router>
            <Routes>
                <Route exact path='/' element={<LandingScreen />} />
                <Route strict path="/video/:videoId" element={<DetailVideoScreen />} />
                <Route exact path="/contact" element={<ContactScreen />} />

                <Route exact path='/admin' element={<LoginRouter />}>
                    <Route exact path='/admin' element={<LoginScreen />} />
                </Route>

                <Route exact path='/home' element={<PrivateRouter />}>
                    <Route exact path='/home' element={<HomeScreen />} />
                </Route>

                <Route exact path='/video' element={<PrivateRouter />}>
                    <Route exact path='/video' element={<VideoScreen />} />
                </Route>

                <Route path='*' element={<ErrorScreen />} />
            </Routes>
        </Router>
    );
};

export default AppRouter;