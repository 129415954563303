import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import React from "react";
import { NavbarModal } from "../Navbar";
import { FormVideo, DeleteVideo } from "./";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ModalVideo = ({ closeModal, fullScreen, optionForm }) => {
  const titleNavbar = optionForm ? "Editar video" : "Crear video";
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={true}
        TransitionComponent={Transition}
      >
        {fullScreen && (
          <>
            <NavbarModal closeModal={closeModal} titleNavbar={titleNavbar} />
            <FormVideo closeModal={closeModal} optionForm={optionForm} />
          </>
        )}
        {!fullScreen && <DeleteVideo closeModal={closeModal} />}
      </Dialog>
    </div>
  );
};

export default ModalVideo;
