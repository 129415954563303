import { tokenizedGet } from '../helpers/get';

const getUser = async (jwt) => {
    const response = await tokenizedGet('getUsers',jwt.access_token)
    if (response.data.status === 201) {
        return response.data;
    }
    // errores...
    return [];
}
export default getUser;