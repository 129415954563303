import React, { useEffect, useReducer } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import AppRouter from '../../router/AppRouter';
import { LoginContext } from '../../context/LoginContext';
import { authReducer } from '../../reducers/authReducer';
import { useVideo } from '../../hooks/useVideo';

const init = () => {
  return JSON.parse(window.localStorage.getItem('user')) || { logged: false };
}

const MainApp = () => {
  const [jwt, dispatch] = useReducer(authReducer, {}, init);
  const { videos, setVideos, loader, count } = useVideo();

  useEffect(() => {
      localStorage.setItem('user', JSON.stringify(jwt));
  }, [jwt, videos])

  return (
    <LoginContext.Provider value={{ jwt, dispatch, videos, setVideos, loader, count }}>
      <AppRouter />
    </LoginContext.Provider>
  );
};

export default MainApp;