import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import React from 'react'
import { NavbarModal } from '../Navbar';
import FormUser from './FormUser';
import DeleteUser from './DeleteUser';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ModalUser = ({ closeModal, fullScreen, optionForm }) => {
    const titleNavbar = (optionForm)? 'Editar usuario' : 'Crear usuario';
    return (
        <div>
            <Dialog fullScreen={fullScreen} open={true} TransitionComponent={Transition}>
                {fullScreen &&
                    <>
                        <NavbarModal
                            closeModal={closeModal}
                            titleNavbar={titleNavbar}
                        />
                        <FormUser
                            closeModal={closeModal}
                            optionForm={optionForm}
                        />
                    </>
                }
                {!fullScreen &&
                    <DeleteUser
                        closeModal={closeModal}
                    />
                }
            </Dialog>
        </div>
    );
}

export default ModalUser;
