import React, { useContext, useEffect, useState } from 'react'
import { Button, Form, Spinner } from 'react-bootstrap';
import { LoginContext } from '../../context/LoginContext';
import { useLoader } from '../../hooks/useLoader';
import login from '../../services/Login/login';
import './styles.scss';


const initialValues = {
    email: "",
    password: ""
};

const LoginScreen = () => {
    const { dispatch } = useContext(LoginContext);
    const { loader, setLoading } = useLoader();

    const [validated, setValidated] = useState('');

    const [values, setValues] = useState(initialValues);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setValues(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    useEffect(() => {
    }, [validated])


    const submitForm = (event) => {
        event.preventDefault();
        login({
            values,
            dispatch,
            setLoading,
            setValidated
        });
    };

    return (
        <div className="container-fluid container-login">
            <div className="card-login">
                {loader &&
                    <div className="container-loader-login">
                        <Spinner animation="grow" />
                    </div>
                }
                {!loader &&
                    <Form
                        onSubmit={event => {
                            submitForm(event);
                        }}
                    >
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" placeholder="Ingresar email" name="email" required onChange={handleChange} />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Contraseña</Form.Label>
                            <Form.Control type="password" placeholder="Ingresar contraseña" name="password" required onChange={handleChange} />
                            <Form.Control.Feedback type="invalid">
                                Ingrese una contraseña
                            </Form.Control.Feedback>
                        </Form.Group>
                        {
                            (validated !== '') &&
                                <div className="text-danger">
                                    {validated}
                                </div>
                        }
                        <br />
                        <div className='text-center'>
                            <Button variant="outline-dark" type="submit">
                                Login
                            </Button>
                        </div>
                    </Form>
                }
            </div>
        </div>
    );
}

export default LoginScreen;
