import React, { useEffect } from "react";
import { TableUser } from "../../components/User";
import goToTop from "../../hooks/useTop";

const HomeScreen = () => {
  useEffect(() => {
    goToTop();
  }, []);
  return (
    <>
      <div className="container-fluid">
        <TableUser />
      </div>
    </>
  );
};

export default HomeScreen;
