import axios from 'axios';

const tokenizedPut = (URL, id, data, token) => {
    return axios.put(`${process.env.REACT_APP_API}/${URL}` + id, data, { headers: { "Authorization": `Bearer ${token}` } });
}

const putWithoutToken = (URL, id, data) => {
    return axios.put(`${process.env.REACT_APP_API}/${URL}` + id, data);
}

export {
    tokenizedPut,
    putWithoutToken
}