/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import "./styles.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode } from "swiper";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import Fancybox from "./fancybox.js";

const CarouselImage = ({ arrayImages }) => {
  return (
    <div className="container-carousel-image">
      {arrayImages.length > 0 && (
        <>
          <Swiper
            slidesPerView={1.2}
            spaceBetween={15}
            freeMode={true}
            loop={true}
            navigation={true}
            modules={[FreeMode]}
            className="mySwiper"
          >
            <Fancybox>
              {arrayImages.map((image, index) => {
                if (index === 0) {
                  return (
                    <SwiperSlide key={index}>
                      <img src={image} alt="Next" className="next-services" />
                    </SwiperSlide>
                  );
                } else {
                  return (
                    <SwiperSlide key={index}>
                      <a data-fancybox="gallery" href={image}>
                        <img src={image} alt="Next" className="next-services" />
                      </a>
                    </SwiperSlide>
                  );
                }
              })}
            </Fancybox>
          </Swiper>
          <div className="container__options">
            <button
              data-fancybox="gallery"
              href={arrayImages[0]}
              className="btn btn-druki-solid"
            >
              Ver Galería
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default CarouselImage;
