import { tokenizedDelete } from '../helpers/delete';
import swal from 'sweetalert';

const deleteUser = async ({ id_user, users, setUsers, closeModal, setLoading, jwt }) => {
    setLoading(true);
    const response = await tokenizedDelete('deleteUser/', id_user, jwt.access_token);
    if(response.data.status === 201){
        setUsers(users.filter((user) => user.id !== id_user));
        swal({
            icon: 'success',
            text: response.data.message,
            button: 'Aceptar'
        });
        closeModal();
        return setLoading(false);
    }
    // ERRORES..
    return null;
}

export default deleteUser;