import React, { useContext } from 'react';
import { Navigate, Outlet } from "react-router-dom";
import { NavbarComponent } from '../components/Navbar';
import { LoginContext } from '../context/LoginContext';

const PrivateRouter = () => {
    const { jwt } = useContext(LoginContext);

    return (jwt.logged)
        ?
            <>
                <NavbarComponent />
                <Outlet />
            </>
        :
        <Navigate to="/admin" />;
};

export default PrivateRouter;