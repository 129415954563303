
import React, { useContext } from 'react'
import { Button, Form, Spinner } from 'react-bootstrap';
import deleteUser from '../../services/User/deleteUser';
import { UserContext } from "../../context/UserContext";
import { useLoader } from '../../hooks/useLoader';
import { LoginContext } from '../../context/LoginContext';

const DeleteUser = ({ closeModal }) => {

    const { users, setUsers, selectedUser } = useContext(UserContext);
    const { jwt } = useContext(LoginContext);

    const {
        loader,
        setLoading,
    } = useLoader();

    const removeUser = () => {
        const id_user = selectedUser.id;
        deleteUser({
            id_user,
            users,
            setUsers,
            closeModal,
            setLoading,
            jwt
        });
    }

    return (
        <Form
            className="container-fluid p-4 text-center"
            onSubmit={e => {
                e.preventDefault();
                removeUser();
            }}
        >
            {loader &&
                <div className="container-loader-modal">
                    <Spinner animation="grow" />
                </div>
            }
            {!loader &&
                <>
                    <Form.Group className="mb-3">
                        <Form.Label>¿Seguro que desea eliminar el usuario <b>{selectedUser.name + ' ' + selectedUser.lastname}</b>?</Form.Label>
                    </Form.Group>

                    <Button onClick={closeModal} variant="primary" type="submit" className="btn btn-danger mt-3">
                        Cancelar
                    </Button>
                    &nbsp;&nbsp;
                    <Button variant="primary" type="submit" className="btn btn-default btn-success mt-3">
                        Confirmar
                    </Button>
                </>
            }

        </Form>
    );
}

export default DeleteUser;
