import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";
import { Link } from "react-router-dom";
import {
  faArrowLeft,
  faPlay,
  faPause,
  faBackwardStep,
  faShareNodes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "react-bootstrap";

const BannerDetail = (result_video) => {
  const [playStopButton, setPlayStopButton] = useState(false);
  const [video, setVideo] = useState(null);
  const [currentTimeElement, setCurrentTimeElement] = useState("");
  const [durationTimeElement, setDurationTimeElement] = useState("");
  const [progressFill, setProgressFill] = useState("0%");
  const refWidthVideoProgress = useRef(null);
  const [widthVideoProgress, setWidthVideoProgress] = useState(0);
  const videoSelected = result_video.result_video;
  const [modalContact, setModalContact] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setWidthVideoProgress(
        refWidthVideoProgress.current.getBoundingClientRect().width
      );
    }, 2000);
  }, []);

  const videoRef = (ref) => {
    if (ref) {
      setVideo(ref);
      let durationMinutes = Math.floor(video?.duration / 60);
      let durationSeconds = Math.floor(video?.duration - durationMinutes / 60);
      setDurationTimeElement(
        isNaN(durationMinutes)
          ? ""
          : `${durationMinutes}:${
              durationSeconds < 10 ? "0" + durationSeconds : durationSeconds
            }`
      );
    }
  };

  const playVideo = () => {
    if (video.paused) {
      video.play();
      setPlayStopButton(true);
    } else {
      video.pause();
      setPlayStopButton(false);
    }
  };

  const updateProgressVideo = () => {
    let currentMinutes = Math.floor(video.currentTime / 60);
    let currentSeconds = Math.floor(video.currentTime - currentMinutes * 60);
    setCurrentTimeElement(
      `${currentMinutes}:${
        currentSeconds < 10 ? "0" + currentSeconds : currentSeconds
      }`
    );
    const percentage = (video.currentTime / video.duration) * 100;
    setProgressFill(percentage + "%");
  };

  const changeProgressVideo = (event) => {
    const progressTime =
      (event.nativeEvent.offsetX / widthVideoProgress) * video.duration;
    setCurrentTimeElement(progressTime.toFixed(2));
    const percentage = (progressTime / video.duration) * 100;
    setProgressFill(percentage + "%");
    video.currentTime = progressTime.toFixed(2);
  };

  // const fullScreen = () => {
  //   video.requestFullscreen
  //     ? video.requestFullscreen()
  //     : video.mozRequestFullScreen();
  // };

  const resetVideo = () => {
    video.currentTime = 0;
  };

  // const openVideoFullScreen = () => {
  //   setModalContact(true);
  // };

  let portada_video;

  switch (true) {
    case videoSelected.name === "Golf Termas":
      portada_video = "termas.jpg";
      break;
    case videoSelected.name === "Club Átletico Tucumán":
      portada_video = "atletico.jpg";
      break;
    case videoSelected.name === "Estancia Cafayate":
      portada_video = "cafayate.jpg";
      break;

    case videoSelected.name === "Estancia La Paz":
      portada_video = "paz.jpg";
      break;
    case videoSelected.name === "Don Druky":
      portada_video = "don.jpg";
      break;
    default:
      portada_video = "termas.jpg";
      break;
  }

  return (
    <>
      <>
        <section className="container-banner SectionVideoPlaying">
          <div className="container__icons__banner__top">
            <Link to={"/"} className="circle__icons">
              <FontAwesomeIcon icon={faArrowLeft} />
            </Link>
            {/* <div className="circle__icons" onClick={openVideoFullScreen}>
              <FontAwesomeIcon icon={faExpand} />
            </div> */}
          </div>
          <div id="video-banner-detail" className="container-video-play">
            <video
              src={`${process.env.REACT_APP_BACKEND + videoSelected.video}`}
              id="detailVideo"
              onTimeUpdate={updateProgressVideo}
              className="body-video"
              ref={videoRef}
              poster={`${
                process.env.REACT_APP_FRONTEND + "assets/image/" + portada_video
              }`}
            />
          </div>
          <div className="container__icons__banner__bottom">
            <div
              className="container__buffer__video"
              onClick={changeProgressVideo}
            >
              <div className="progress__video" ref={refWidthVideoProgress}>
                <div
                  className="progress__fill__video"
                  style={{ marginLeft: progressFill }}
                ></div>
              </div>
              <div className="time__video">
                <span>{currentTimeElement}</span>
                <span>{durationTimeElement}</span>
              </div>
            </div>
            <div className="container__controls__icons">
              <div className="controls_icons transparent" onClick={resetVideo}>
                <FontAwesomeIcon icon={faBackwardStep} />
              </div>
              <div className="controls_icons" onClick={playVideo}>
                {playStopButton ? (
                  <FontAwesomeIcon icon={faPause} />
                ) : (
                  <FontAwesomeIcon
                    icon={faPlay}
                    style={{ marginLeft: "4px" }}
                  />
                )}
              </div>
              <div className="controls_icons transparent">
                <FontAwesomeIcon icon={faShareNodes} />
              </div>
            </div>
          </div>
        </section>
        <Modal
          show={modalContact}
          aria-labelledby="contained-modal-title-vcenter"
          className="modal__full__screen"
        >
          <FontAwesomeIcon
            icon={faArrowLeft}
            onClick={() => setModalContact(false)}
            className="close__modal"
          />
          <Modal.Body>
            <video
              loop
              controls
              src={`${process.env.REACT_APP_BACKEND + videoSelected.video}`}
            />
          </Modal.Body>
        </Modal>
      </>
    </>
  );
};

export default BannerDetail;
