import { postWithoutToken } from '../helpers/post';
import { types } from '../../reducers/types';

const login = async ({ values, dispatch, setLoading, setValidated }) => {
    setLoading(true);
    const response = await postWithoutToken('login', values);
    if (response.data.status === 201) {
        dispatch({
            type: types.login,
            payload: response.data
        });
        setValidated('');
        return setLoading(false);
    }
    // errores
    const { message } = response.data;
    let error = '';
    if (message instanceof Object) {
        error = Object.values(message)
            .map(item => item.join(', ')).join(', ');
    } else {
        error = message;
    }
    setValidated(error);
    return setLoading(false);
};

export default login;