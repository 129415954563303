import { Box, Button, CircularProgress, Grid, TextField } from "@mui/material";
import { React, useState } from "react";
import sendForm from "../../services/form/sendForm";
import "./styles.scss";

const initialValues = {
  name_person: "",
  mail: "",
  phone: "",
  subject: "",
  message: "",
};

const FormContact = () => {
  const [validated, setValidated] = useState("");
  const [values, setValues] = useState(initialValues);
  const [loader, setLoader] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorNombre, setErrorNombre] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [mjeErrorEmail, setMjeErrorEmail] = useState("");
  const [errorAsunto, setErrorAsunto] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const submitForm = (event) => {
    event.preventDefault();
    sendForm(
      values,
      setLoader,
      setValidated,
      setErrorNombre,
      setErrorEmail,
      setErrorAsunto,
      setMjeErrorEmail,
      setValues,
      initialValues,
      setSuccess
    );
  };

  return (
    <Grid container spacing={2}>
      <>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              maxWidth: "100%",
            }}
          >
            {!errorNombre && (
              <TextField
                className="input__contact"
                fullWidth
                label="Nombre *"
                name="name_person"
                onChange={handleChange}
                value={values.name_person}
              />
            )}
            {errorNombre && (
              <TextField
                className="input__contact"
                fullWidth
                label="Nombre *"
                name="name_person"
                onChange={handleChange}
                error
                helperText="Ingrese un nombre."
                value={values.name_person}
              />
            )}
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box
            sx={{
              maxWidth: "100%",
            }}
          >
            {!errorEmail && (
              <TextField
                className="input__contact"
                fullWidth
                label="Correo Electrónico *"
                name="mail"
                onChange={handleChange}
                value={values.mail}
              />
            )}
            {errorEmail && (
              <TextField
                className="input__contact"
                fullWidth
                label="Correo Electrónico *"
                name="mail"
                onChange={handleChange}
                error
                helperText={mjeErrorEmail}
                value={values.mail}
              />
            )}
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box
            sx={{
              maxWidth: "100%",
            }}
          >
            <TextField
              className="input__contact"
              fullWidth
              label="Teléfono"
              name="phone"
              onChange={handleChange}
              value={values.phone}
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box
            sx={{
              maxWidth: "100%",
            }}
          >
            {!errorAsunto && (
              <TextField
                className="input__contact"
                fullWidth
                label="Asunto *"
                name="subject"
                onChange={handleChange}
                value={values.subject}
              />
            )}
            {errorAsunto && (
              <TextField
                className="input__contact"
                fullWidth
                label="Asunto *"
                name="subject"
                onChange={handleChange}
                error
                helperText="Ingrese un asunto."
                value={values.subject}
              />
            )}
          </Box>
        </Grid>

        <Grid item xs={12} md={12}>
          <Box
            sx={{
              maxWidth: "100%",
            }}
          >
            <TextField
              className="input__contact"
              fullWidth
              multiline
              label="Mensaje"
              name="message"
              rows={4}
              onChange={handleChange}
              value={values.message}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={12}>
          {validated !== "" && (
            <div className={success ? "text__succes" : "text__danger"}>
              {validated}
            </div>
          )}
        </Grid>

        <Grid className="container__btn__contact" item xs={12} md={12}>
          <div className="d-flex justify-content-center">
          <Button
            className="btn__druki__contact"
            variant="outlined"
            onClick={submitForm}
          > 
              {!loader && <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-send-fill mr-2" viewBox="0 0 16 16">
                <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083l6-15Zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471-.47 1.178Z"/>
              </svg>}
            {!loader && "Enviar"}
            
            {loader && (
              <CircularProgress className="loader__contact" size={22} />
            )}
          </Button>
          </div>
        </Grid>
      </>
    </Grid>
  );
};

export default FormContact;
