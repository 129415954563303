import { tokenizedPostData } from '../helpers/post';
import swal from 'sweetalert';

const postVideo = async ({ formData, videos, setVideos, closeModal, setLoading, setValidated, jwt }) => {
    setLoading(true);
    const response = await tokenizedPostData('createVideo', formData, jwt.access_token);

    if (response.data.status === 201) {
        setVideos([...videos,response.data.video]);
        swal({
            icon: 'success',
            text: response.data.message,
            button: 'Aceptar'
        });
        closeModal();
        return setLoading(false);
    }
    // errores
    const { message } = response.data;
    let error = '';
    if (message instanceof Object) {
        error = Object.values(message)
            .map(item => item.join(', ')).join(', ');
    } else {
        error = message;
    }
    setValidated(error);
    return setLoading(false);
}

export default postVideo;