import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import Menu from "./Menu";
import "./styles.scss";

const BannerContact = () => {
  return (
    <>
      <section className="container-banner">
        <div className="fvl-navbar d-inline-flex">
          <Link to={"/"} className="circle__icons color-primary">
            <FontAwesomeIcon icon={faArrowLeft} />
          </Link>
          <Menu />
        </div>
      </section>
    </>
  );
};

export default BannerContact;
