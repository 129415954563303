import { useContext, useEffect, useMemo, useState } from 'react';
import { LoginContext } from '../context/LoginContext';
import getUser from '../services/User/getUser';
import { useLoader } from './useLoader';

export const useUser = () => {
    const [users, setUsers] = useState([]);
    const { loader, setLoading } = useLoader();
    const { jwt } = useContext(LoginContext);

    useEffect(() => {
        setLoading(true);
        getUser(jwt).then(usuarios => {
            setUsers(usuarios.users);
            setLoading(false);
        }).catch(error => {
            setUsers(error);
        });
    }, [jwt, setLoading]);

    const value = useMemo(() => ({
        users, setUsers, loader
    }), [users, setUsers, loader]);

    return value;
};
