import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./styles.scss";
import { LoginContext } from "../../context/LoginContext";
import { Animated } from "react-animated-css";
import Menu from "./Menu";

let url_video;
let url_detail;
let url_name_vector;
let name_video;
let resume;
let url_vector;
const Banner = () => {
  const { videos, count } = useContext(LoginContext);
  const [videoSelected, setVideoSelected] = useState([]);
  const [transitionVideo, setTransitionVideo] = useState(true);

  useEffect(() => {}, [videos, count, videoSelected]);

  const videoChange = (videoSelected, action) => {
    setTransitionVideo(false);
    setTimeout(() => {
      setTransitionVideo(true);
    }, 1500);

    const index = videos.findIndex((video) => videoSelected.id === video.id);
    const result = action ? index + 1 : index - 1;
    if (count === result) {
      setTimeout(() => {
        setVideoSelected(videos[0]);
      }, 750);
    } else if (result < 0) {
      setTimeout(() => {
        setVideoSelected(videos[count - 1]);
      }, 750);
    } else {
      setTimeout(() => {
        setVideoSelected(videos[result]);
      }, 750);
    }
  };

  return (
    <>
      {(videoSelected.length !== 0 || videos.length !== 0) && (
        <>
          <div className="d-none">
            {
              (url_video =
                videoSelected.length !== 0
                  ? videoSelected?.video_banner
                  : videos[0]?.video_banner)
            }
            {
              (url_detail =
                videoSelected.length !== 0 ? videoSelected?.id : videos[0]?.id)
            }
            {
              (url_name_vector =
                videoSelected.length !== 0
                  ? videoSelected?.name_svg
                  : videos[0]?.name_svg)
            }
            {
              (name_video =
                videoSelected.length !== 0
                  ? videoSelected?.name
                  : videos[0]?.name)
            }
            {
              (resume =
                videoSelected.length !== 0
                  ? videoSelected?.resume
                  : videos[0]?.resume)
            }
            {
              (url_vector =
                videoSelected.length !== 0
                  ? videoSelected?.vector
                  : videos[0]?.vector)
            }
          </div>
          <section className="container-banner">
            <div className="fvl-navbar d-inline-flex">
              <img
                src={`${
                  process.env.REACT_APP_FRONTEND + "assets/image/full-logo.svg"
                }`}
                alt="Logo Druky"
                className="logo-druky"
              />
              <Menu />
            </div>

            <div className="container-video-play">
              <Animated
                animationIn="fadeIn"
                animationOut="fadeOut"
                isVisible={transitionVideo}
              >
                <video
                  autoPlay
                  muted
                  loop
                  className="body-video"
                  src={`${process.env.REACT_APP_BACKEND + url_video}`}
                />
                <div className="divider-video-container"></div>
              </Animated>

              <div className="content__arrows">
                <span>
                  <img
                    className="btn btn-druki"
                    src={`${
                      process.env.REACT_APP_FRONTEND +
                      "assets/image/arrow_left.svg"
                    }`}
                    alt="Previous"
                    onClick={() =>
                      videoChange(
                        videoSelected.length !== 0 ? videoSelected : videos[0],
                        false
                      )
                    }
                  />
                </span>
                <span>
                  <Link to={"/video/" + url_detail} className="btn btn-druki">
                    Ver Capítulo
                  </Link>
                </span>
                <span>
                  <img
                    className="btn btn-druki"
                    src={`${
                      process.env.REACT_APP_FRONTEND +
                      "assets/image/arrow_right.svg"
                    }`}
                    alt="Next"
                    onClick={() =>
                      videoChange(
                        videoSelected.length !== 0 ? videoSelected : videos[0],
                        true
                      )
                    }
                  />
                </span>
              </div>
            </div>
            <Link to={"/video/" + url_detail}>
              <div className="container-videos">
                <div className="content-video">
                  <Animated
                    animationIn="fadeIn"
                    animationOut="fadeOut"
                    isVisible={transitionVideo}
                  >
                    <div className="container-description-video">
                      <section className="Presentation_Chapter">
                        <div id="Title_Chapter">
                          <Animated
                            animationIn="fadeInLeft"
                            animationOut="fadeOutLeft"
                            isVisible={transitionVideo}
                          >
                            <img
                              className="title-chapter"
                              src={`${
                                process.env.REACT_APP_BACKEND + url_name_vector
                              }`}
                              alt={`${
                                process.env.REACT_APP_BACKEND + name_video
                              }`}
                            />
                            <p className="resume-video resume-video-mobile">
                              {resume}
                            </p>
                          </Animated>
                        </div>
                      </section>

                      <section
                        id="section-vector-video"
                        className={`${name_video}`}
                      >
                        <img
                          src={`${process.env.REACT_APP_BACKEND + url_vector}`}
                          alt={`${process.env.REACT_APP_BACKEND + name_video}`}
                          className={`vector-video " ${name_video}`}
                        />
                        <div className="container__bosque">
                          <img
                            className="bosques_svg"
                            src={`${
                              process.env.REACT_APP_FRONTEND +
                              "assets/image/vector_bosque.svg"
                            }`}
                            alt="Bosque"
                          />
                        </div>
                      </section>
                    </div>
                  </Animated>
                </div>
              </div>
            </Link>

            <section className="container__bosque_02">
              <img
                className="bosques_svg"
                src={`${
                  process.env.REACT_APP_FRONTEND +
                  "assets/image/vector_bosque_02.svg"
                }`}
                alt="Bosque"
              />
            </section>
          </section>

          <section className="container-sombrilla">
            <div className="svg-babeta"></div>
          </section>
          <section className="container__foundation">
            <Animated
              animationIn="fadeIn"
              animationOut="fadeOut"
              isVisible={transitionVideo}
            >
              <div className="content__arrows content__arrows__desktop">
                <span>
                  <img
                    className="btn btn-druki"
                    src={`${
                      process.env.REACT_APP_FRONTEND +
                      "assets/image/arrow_left.svg"
                    }`}
                    alt="Previous"
                    onClick={() =>
                      videoChange(
                        videoSelected.length !== 0 ? videoSelected : videos[0],
                        false
                      )
                    }
                  />
                </span>
                <span>
                  <Link to={"/video/" + url_detail} className="btn btn-druki">
                    Ver Capítulo
                  </Link>
                </span>
                <span>
                  <img
                    className="btn btn-druki"
                    src={`${
                      process.env.REACT_APP_FRONTEND +
                      "assets/image/arrow_right.svg"
                    }`}
                    alt="Next"
                    onClick={() =>
                      videoChange(
                        videoSelected.length !== 0 ? videoSelected : videos[0],
                        true
                      )
                    }
                  />
                </span>
              </div>
              <p className="resume-video">{resume}</p>
              {/* <p className="company-description">
                <strong>¿Quienés somos?</strong>
                <br></br>
                <br></br>
                Druky es una compañía familiar fundada en el año
                1992 abocada al diseño, construcción y
                asesoramiento en el desarrollo de campos de juego
                de competencias de alto rendimiento, y de espacios
                verdes.
                Priorizamos mantener un feedback constante con el
                cliente a través del compromiso, la escucha activa y
                el asesoramiento personalizado, esto se ve reflejado
                en cada uno de los proyectos que llevamos a cabo,
                satisfaciendo las necesidades de cada uno de
                nuestros clientes a través de altos estándares de
                calidad.
                Como empresa nos enfocamos en la preservación y el
                cuidado de medioambiente ofreciendo soluciones
                integrales, obteniendo así proyectos armónicos y
                responsables.
                <br></br>
                _
                <br></br>
                <br></br>
                <br></br>
              <strong>Nuestros servicios</strong>
              <br></br>
                _
              </p> */}
            </Animated>
          </section>
        </>
      )}
    </>
  );
};

export default Banner;
