import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from '@material-ui/core/IconButton';
import React from 'react'
import { Nav, Navbar } from 'react-bootstrap';
import './styles.scss';

const NavbarModal = ({ titleNavbar, closeModal }) => {

    return (
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
            <Navbar.Brand>
                {titleNavbar}
            </Navbar.Brand>
            <Navbar.Collapse className="justify-content-end">
                <Nav>
                    <IconButton color="inherit" aria-label="close" onClick={closeModal}>
                        <FontAwesomeIcon className="icon-boton" icon={faTimes} />
                    </IconButton>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}

export default NavbarModal;
