import React, { useEffect } from "react";
import goToTop from "../../hooks/useTop";

const ErrorScreen = () => {
  useEffect(() => {
    goToTop();
  }, []);
  return <div className="container-fluid">Page Not Found</div>;
};

export default ErrorScreen;
