import React, { useEffect } from "react";
import BannerContact from "../../components/Landing/BannerContact";
import Footer from "../../components/Landing/Footer";
import { FormContact } from "../../components/shared";
import goToTop from "../../hooks/useTop";
import "./styles.scss";

const ContactScreen = () => {
  useEffect(() => {
    goToTop();
  }, []);

  return (
    <div className="content-main">
      <BannerContact />
      <div className="container__form__contact">
      
      <div className="d-flex justify-content-star w-100"><h4>Contacto</h4></div>
        <FormContact />
      </div>
      <Footer />
    </div>
  );
};

export default ContactScreen;
