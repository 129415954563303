import React, { useContext, useState } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import putUser from "../../services/User/putUser";
import postVideo from "../../services/Video/postVideo";
import { VideoContext } from "../../context/VideoContext";
import { useLoader } from "../../hooks/useLoader";
import { LoginContext } from "../../context/LoginContext";

const initialValues = {
  id: "",
  name: "",
  resume: "",
  description: "",
  name_svg: null,
  vector: null,
  vector_silueta: null,
  position_vector: "right",
  image: null,
  video: null,
  video_banner: null,
  gallery: [],
};

const FormUser = ({ closeModal, optionForm }) => {
  const { selectedVideo } = useContext(VideoContext);
  const { jwt, videos, setVideos } = useContext(LoginContext);

  const { loader, setLoading } = useLoader();

  const [validated, setValidated] = useState("");

  const [values, setValues] = useState(selectedVideo || initialValues);

  const handleChange = (event) => {
    const { name, value } = event.target;
    let valores = "";
    if (
      name === "name_svg" ||
      name === "image" ||
      name === "video" ||
      name === "video_banner" ||
      name === "vector" ||
      name === "vector_silueta"
    ) {
      valores = event.target.files[0];
    } else if (name === "gallery") {
      valores = event.target.files;
    } else {
      valores = value;
    }

    setValues((prevState) => ({
      ...prevState,
      [name]: valores,
    }));
  };

  const createVideo = () => {
    let formData = new FormData();
    formData.append("name", values.name);
    formData.append("resume", values.resume);
    formData.append("description", values.description);
    formData.append("name_svg", values.name_svg);
    formData.append("image", values.image);
    formData.append("video", values.video);
    formData.append("video_banner", values.video_banner);
    formData.append("vector", values.vector);
    formData.append("vector_silueta", values.vector_silueta);
    formData.append("position_vector", values.position_vector);

    if (values.gallery.length > 0) {
      for (let index = 0; index < values.gallery.length; index++) {
        formData.append("gallery[]", values.gallery[index]);
      }
    }

    postVideo({
      formData,
      videos,
      setVideos,
      closeModal,
      setLoading,
      setValidated,
      jwt,
    });
  };

  const updateUser = () => {
    putUser({
      values,
      videos,
      setVideos,
      closeModal,
      setLoading,
      setValidated,
      jwt,
    });
  };

  const submitForm = () => {
    optionForm ? updateUser() : createVideo();
  };

  return (
    <Form
      className="container-fluid p-4"
      onSubmit={(e) => {
        e.preventDefault();
        submitForm();
      }}
    >
      {loader && (
        <div className="container-loader-modal">
          <Spinner animation="grow" />
        </div>
      )}
      {!loader && (
        <>
          <Form.Group className="mb-3">
            <Form.Label>Título</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Ingresar título"
              name="name"
              onChange={(event) => handleChange(event)}
              value={values.name}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Resumen</Form.Label>
            <Form.Control
              type="text"
              placeholder="Ingresar resumen"
              name="resume"
              onChange={(event) => handleChange(event)}
              value={values.resume}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Descripción</Form.Label>
            <Form.Control
              as="textarea"
              type="text"
              placeholder="Ingresar descripción"
              name="description"
              onChange={(event) => handleChange(event)}
              value={values.description}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Título SVG</Form.Label>
            <Form.Control
              type="file"
              name="name_svg"
              onChange={(event) => handleChange(event)}
            />
          </Form.Group>

          <Row>
            <Col xs={12} md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Vector</Form.Label>
                <Form.Control
                  type="file"
                  name="vector"
                  onChange={(event) => handleChange(event)}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Posición Vector</Form.Label>
                <Form.Select
                  className="form-control"
                  name="position_vector"
                  onChange={(event) => handleChange(event)}
                >
                  <option value="right">Derecha</option>
                  <option value="left">Izquierda</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Vector Silueta</Form.Label>
                <Form.Control
                  type="file"
                  name="vector_silueta"
                  onChange={(event) => handleChange(event)}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Portada</Form.Label>
                <Form.Control
                  type="file"
                  name="image"
                  onChange={(event) => handleChange(event)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Video Completo</Form.Label>
                <Form.Control
                  type="file"
                  name="video"
                  onChange={(event) => handleChange(event)}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Video Portada</Form.Label>
                <Form.Control
                  type="file"
                  name="video_banner"
                  onChange={(event) => handleChange(event)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Form.Group className="mb-3">
            <Form.Label>Galería</Form.Label>
            <Form.Control
              type="file"
              name="gallery"
              multiple
              onChange={(event) => handleChange(event)}
            />
          </Form.Group>

          {validated !== "" && <div className="text-danger">{validated}</div>}
          <br />
          <Button
            variant="primary"
            type="submit"
            className="btn btn-default btn-success mt-3 float-right"
          >
            Confirmar
          </Button>
          <Button
            onClick={closeModal}
            variant="primary"
            type="submit"
            className="btn btn-danger mt-3 mr-2 float-right"
          >
            Cancelar
          </Button>
        </>
      )}
    </Form>
  );
};

export default FormUser;
