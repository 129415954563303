import axios from 'axios';

const tokenizedDelete = (URL, id, token) => {
    return axios.delete(`${process.env.REACT_APP_API}/${URL}` + id, { headers: { "Authorization": `Bearer ${token}` } });
}

const deleteWithoutToken = (URL, id) => {
    return axios.delete(`${process.env.REACT_APP_API}/${URL}` + id);
}

export {
    tokenizedDelete,
    deleteWithoutToken
}