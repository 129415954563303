import { tokenizedPut } from '../helpers/put';
import swal from 'sweetalert';

const deleteVideo = async ({ selectedVideo, videos, setVideos, closeModal, setLoading, jwt }) => {
    setLoading(true);
    const response = await tokenizedPut('deleteVideo/', selectedVideo.id, selectedVideo, jwt.access_token);

    if(response.data.status === 201){
        setVideos(videos.filter((video) => video.id !== selectedVideo.id));
    }
    // errores
    swal({
        icon: 'success',
        text: response.data.message,
        button: 'Aceptar'
    });
    closeModal();
    return setLoading(false);
}

export default deleteVideo;