import {
  faBars,
  faSignOutAlt,
  faUser,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useRef, useState } from "react";
import { Nav, Navbar, Overlay, Popover } from "react-bootstrap";
import { Link } from "react-router-dom";
import { LoginContext } from "../../context/LoginContext";
import logout from "../../services/Login/logout";
import "./styles.scss";

const NavbarComponent = () => {
  const { jwt, dispatch } = useContext(LoginContext);

  const handleLogout = () => {
    logout({ dispatch });
  };

  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);

  const handleClick = (event) => {
    setShow(!show);
    setTarget(event.target);
  };
  return (
    <>
      <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Navbar.Brand>
          <Link to="/home" className="color-navbar">
            DRUKI
          </Link>
        </Navbar.Brand>
        <Navbar.Collapse className="justify-content-end">
          <div ref={ref}>
            <a href="#" className="btn-burger" onClick={handleClick}>
              <FontAwesomeIcon className="icon-navbar-danger" icon={faBars} />
            </a>

            <Overlay
              show={show}
              target={target}
              placement="bottom"
              container={ref}
              containerPadding={20}
            >
              <Popover className="container-menu" id="popover-contained">
                <Popover.Header as="h3">Menu</Popover.Header>
                <Popover.Body>
                  <Nav className="menu-divider">
                    <Link to="/home" className="color-navbar-dropdown">
                      <FontAwesomeIcon
                        className="icon-navbar-danger"
                        icon={faUser}
                      />
                      &nbsp;&nbsp;Usuarios
                    </Link>
                  </Nav>
                  <div className="line-divider" />
                  <Nav className="menu-divider">
                    <Link to="/video" className="color-navbar-dropdown">
                      <FontAwesomeIcon
                        className="icon-navbar-danger"
                        icon={faVideo}
                      />
                      &nbsp;&nbsp;Videos
                    </Link>
                  </Nav>
                  <div className="line-divider" />
                  <Nav className="menu-divider">
                    <Link
                      to="/admin"
                      className="color-navbar-dropdown"
                      onClick={handleLogout}
                    >
                      <FontAwesomeIcon
                        className="icon-navbar-danger"
                        icon={faSignOutAlt}
                      />
                      &nbsp;&nbsp;Cerrar sesión
                    </Link>
                  </Nav>
                </Popover.Body>
              </Popover>
            </Overlay>
          </div>
        </Navbar.Collapse>
      </Navbar>
      <div className="container-bottom">
        <div className="container-welcome">
          Bienvenido {jwt.user.name} {jwt.user.lastname}
        </div>
      </div>
    </>
  );
};

export default NavbarComponent;
