import React, { useContext } from 'react';
import { Navigate, Outlet } from "react-router-dom";
import { LoginContext } from '../context/LoginContext';

const LoginRouter = () => {
    const { jwt } = useContext(LoginContext);

    return (!jwt.logged) ? <Outlet /> : <Navigate to="/home" />;
};

export default LoginRouter;