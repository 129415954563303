import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { BannerDetail } from "../../components/Landing";
import CarouselImage from "../../components/Landing/CarouselImage";
import { LoginContext } from "../../context/LoginContext";
import goToTop from "../../hooks/useTop";
import Loader from "../../components/Loader/Loader";
import Menu from "../../components/Landing/Menu";

const DetailVideoScreen = () => {
  const [buffer, setBuffer] = useState(false);
  const { videoId } = useParams();
  const { videos } = useContext(LoginContext);
  const [videoSelected, setVideoSelected] = useState(null);
  const [arrayImg, setArrayImg] = useState([]);

  useEffect(() => {
    setTimeout(() => {
      setBuffer(true);
    }, 1000);
  }, []);

  useEffect(() => {
    if (videos) {
      const result_video = videos.filter(
        (video) => parseInt(video.id, 10) === parseInt(videoId, 10)
      );
      goToTop();
      setVideoSelected(result_video[0]);
      const arrayImage = [];
      result_video[0]?.get_documents?.forEach((document) => {
        arrayImage.push(process.env.REACT_APP_BACKEND + document.link);
      });
      setArrayImg(arrayImage);
    }
  }, [videos, videoId]);

  return (
    <>
      {buffer ? (
        <div id="DetailVideoScreen" className="content-main">
          <div className="fvl-navbar d-inline-flex">
            <img
              src={`${
                process.env.REACT_APP_FRONTEND + "assets/image/full-logo.svg"
              }`}
              alt="Logo Druky"
              className="logo-druky"
            />
            <Menu />
          </div>

          {videoSelected && (
            <>
              <div id="Section_Detail_Video" className="">
                <section className="content__description__video">
                  <img
                    src={`${
                      process.env.REACT_APP_BACKEND + videoSelected?.name_svg
                    }`}
                    alt={`${
                      process.env.REACT_APP_BACKEND + videoSelected?.name
                    }`}
                  />
                  <p>{videoSelected?.description}</p>
                </section>
                <div className="d-flex flex-column">
                  <section>
                    <BannerDetail result_video={videoSelected} />
                  </section>
                </div>
              </div>
            </>
          )}
          <section className="content__gallery_images">
            <CarouselImage arrayImages={arrayImg} />
          </section>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default DetailVideoScreen;
