import React, { useEffect, useState } from 'react'
import { UserContext } from '../../context/UserContext';
import MaterialTable from 'material-table';
import { useUser } from '../../hooks/useUser';
import ModalUser from './ModalUser';
import './styles.scss';
import { IconButton } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Loader from '../Loader/Loader';

const calculateAge = (birthday) => {
    const birthday_arr = birthday.split("-");
    const birthday_date = new Date(birthday_arr[0], birthday_arr[1] - 1, birthday_arr[2]);
    const ageDifMs = Date.now() - birthday_date.getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
}

const columnsTable = [
    {
        title: 'ID',
        field: 'id',
        sortable: true,
        grow: 1
    },
    {
        title: 'NOMBRE',
        field: 'name',
        sortable: true,
        grow: 3
    },
    {
        title: 'APELLIDO',
        field: 'lastname',
        sortable: true,
        grow: 3
    },
    {
        title: 'EDAD',
        field: 'birth_date',
        sortable: true,
        grow: 3,
        render: rowData => {
            return (
                <>
                    {calculateAge(rowData.birth_date)}
                </>
            )
        }
    },
    {
        title: 'EMAIL',
        field: 'email',
        sortable: true,
        grow: 3
    }
];

const TableUser = () => {
    const [selectedUser, setSelectedUser] = useState();

    const [modalCreate, setModalCreate] = useState(false);
    const [modalEdit, setModalEdit] = useState(false);
    const [modalDelete, setModalDelete] = useState(false);

    const {
        users,
        setUsers,
        loader,
    } = useUser();

    useEffect(() => {
    }, [users]);

    const openModalCreate = () => {
        setSelectedUser(null);
        setModalCreate(true);
    };

    const closeModalCreate = () => {
        setModalCreate(false);
    }

    const openModalUpdate = (rowData) => {
        setSelectedUser(rowData);
        setModalEdit(true);
    };

    const closeModalUpdate = () => {
        setModalEdit(false);
    }

    const openModalDelete = (rowData) => {
        setSelectedUser(rowData);
        setModalDelete(true);
    };

    const closeModalDelete = () => {
        setModalDelete(false);
    }

    return (
        <UserContext.Provider value={{ users, setUsers, selectedUser }}>
            <div className="container-table">
                <div className="container-add">
                    <IconButton color="inherit" aria-label="close" onClick={openModalCreate}>
                        <FontAwesomeIcon className="icon-boton" icon={faPlus} />
                    </IconButton>
                </div>

                {loader &&
                    <Loader />
                }
                {!loader &&
                    <MaterialTable
                        title='Listado de usuarios'
                        columns={columnsTable}
                        data={users}
                        actions={[
                            {
                                icon: 'edit',
                                tooltip: 'Editar usuario',
                                onClick: (event, rowData) => openModalUpdate(rowData)
                            },
                            {
                                icon: 'delete',
                                tooltip: 'Eliminar usuario',
                                onClick: (event, rowData) => openModalDelete(rowData)
                            }
                        ]}
                        localization={{
                            header: {
                                actions: 'Acciones'
                            },
                            pagination: {
                                labelRowsSelect: 'filas',
                                labelDisplayedRows: '{from}-{to} de {count}',
                                firstAriaLabel: 'Primera página',
                                firstTooltip: 'Primera página',
                                previousTooltip: 'Anterior',
                                nextTooltip: 'Siguiente',
                                lastTooltip: 'Última página'
                            },
                            toolbar: {
                                exportTitle: 'Descargar',
                                exportAriaLabel: 'Descargar',
                                searchPlaceholder: 'Buscar'
                            }
                        }}
                        options={{
                            pageSize: 3,
                            pageSizeOptions: [3, 5, 10, 20, 30, 50, 75, 100],
                            actionsColumnIndex: -1,
                            exportButton: true,
                            exportFileName: 'Usuarios DRUKI',
                            sorting: true,
                            headerStyle: {
                                backgroundColor: '#f6f9fc',
                                color: '#8898aa',
                                fontSize: '1rem',
                                fontWeight: '700'
                            },
                            rowStyle: {
                                color: '#525f7f',
                                fontSize: '0.9rem'
                            }
                        }}
                    />
                }
                {
                    modalCreate &&
                    <ModalUser
                        closeModal={closeModalCreate}
                        fullScreen={true}
                        optionForm={false}
                    />
                }
                {
                    modalEdit &&
                    <ModalUser
                        closeModal={closeModalUpdate}
                        fullScreen={true}
                        optionForm={true}
                    />
                }
                {
                    modalDelete &&
                    <ModalUser
                        closeModal={closeModalDelete}
                        fullScreen={false}
                    />
                }
            </div>
        </UserContext.Provider>
    );
}

export default TableUser;
