import React, { useContext, useState } from "react";
import { VideoContext } from "../../context/VideoContext";
import MaterialTable from "material-table";
import { ModalVideo } from "./";
import "./styles.scss";
import { IconButton } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Loader from "../Loader/Loader";
import { LoginContext } from "../../context/LoginContext";

const columnsTable = [
  {
    title: "ID",
    field: "id",
    sortable: true,
    grow: 1,
  },
  {
    title: "TÍTULO",
    field: "name",
    sortable: true,
    grow: 3,
  },
];

const TableUser = () => {
  const [selectedVideo, setSelectedVideo] = useState();

  const [modalCreate, setModalCreate] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);

  const { videos, loader } = useContext(LoginContext);

  const openModalCreate = () => {
    setSelectedVideo(null);
    setModalCreate(true);
  };

  const closeModalCreate = () => {
    setModalCreate(false);
  };

  const openModalUpdate = (rowData) => {
    setSelectedVideo(rowData);
    setModalEdit(true);
  };

  const closeModalUpdate = () => {
    setModalEdit(false);
  };

  const openModalDelete = (rowData) => {
    setSelectedVideo(rowData);
    setModalDelete(true);
  };

  const closeModalDelete = () => {
    setModalDelete(false);
  };

  return (
    <VideoContext.Provider value={{ selectedVideo }}>
      <div className="container-table">
        <div className="container-add">
          <IconButton
            color="inherit"
            aria-label="close"
            onClick={openModalCreate}
          >
            <FontAwesomeIcon className="icon-boton" icon={faPlus} />
          </IconButton>
        </div>

        {loader && <Loader />}
        {!loader && (
          <MaterialTable
            title="Listado de videos"
            columns={columnsTable}
            data={videos}
            actions={[
              {
                icon: "edit",
                tooltip: "Editar video",
                onClick: (event, rowData) => openModalUpdate(rowData),
              },
              {
                icon: "delete",
                tooltip: "Eliminar video",
                onClick: (event, rowData) => openModalDelete(rowData),
              },
            ]}
            localization={{
              header: {
                actions: "Acciones",
              },
              pagination: {
                labelRowsSelect: "filas",
                labelDisplayedRows: "{from}-{to} de {count}",
                firstAriaLabel: "Primera página",
                firstTooltip: "Primera página",
                previousTooltip: "Anterior",
                nextTooltip: "Siguiente",
                lastTooltip: "Última página",
              },
              toolbar: {
                exportTitle: "Descargar",
                exportAriaLabel: "Descargar",
                searchPlaceholder: "Buscar",
              },
            }}
            options={{
              pageSize: 3,
              pageSizeOptions: [3, 5, 10, 20, 30, 50, 75, 100],
              actionsColumnIndex: -1,
              exportButton: true,
              exportFileName: "Videos DRUKI",
              sorting: true,
              headerStyle: {
                backgroundColor: "#f6f9fc",
                color: "#8898aa",
                fontSize: "1rem",
                fontWeight: "700",
              },
              rowStyle: {
                color: "#525f7f",
                fontSize: "0.9rem",
              },
            }}
          />
        )}
        {modalCreate && (
          <ModalVideo
            closeModal={closeModalCreate}
            fullScreen={true}
            optionForm={false}
          />
        )}
        {modalEdit && (
          <ModalVideo
            closeModal={closeModalUpdate}
            fullScreen={true}
            optionForm={true}
          />
        )}
        {modalDelete && (
          <ModalVideo closeModal={closeModalDelete} fullScreen={false} />
        )}
      </div>
    </VideoContext.Provider>
  );
};

export default TableUser;
