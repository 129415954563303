import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { Banner, Carousel } from "../../components/Landing";
import Footer from "../../components/Landing/Footer";
import Loader from "../../components/Loader/Loader";
import { FormContact, ModalServices } from "../../components/shared";
import { LoginContext } from "../../context/LoginContext";
import goToTop from "../../hooks/useTop";
import "./styles.scss";

const LandingScreen = () => {
  const { loader } = useContext(LoginContext);
  const [modalService1, setModalService1] = useState(false);
  const [modalService2, setModalService2] = useState(false);
  const [modalService3, setModalService3] = useState(false);
  const [modalService4, setModalService4] = useState(false);
  const [modalService5, setModalService5] = useState(false);
  const [modalService6, setModalService6] = useState(false);

  useEffect(() => {
    goToTop();
  }, []);

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          <div className="content-main">
            <Banner />

            <Carousel
              modalService1={setModalService1}
              modalService2={setModalService2}
              modalService3={setModalService3}
              modalService4={setModalService4}
              modalService5={setModalService5}
              modalService6={setModalService6}
            />

            <Footer />
          </div>
          <ModalServices
            show={modalService1}
            onHide={() => setModalService1(false)}
            number={1}
            title={"¿En qué podemos ayudarte?"}
            description={
              <div className="container__form__contact__home">
                {/* <Grid item xs={12} md={6} mb={4}>
          <p className="title_contact"></p>
        </Grid> */}
                <FormContact />
              </div>
            }
          />

          <ModalServices
            show={modalService2}
            onHide={() => setModalService2(false)}
            number={2}
            title={"Quiénes Somos"}
            description={
              <p>
                Druky es una compañía familiar fundada en el año 1992 abocada al
                diseño, construcción y asesoramiento en el desarrollo de campos
                de juego de alto rendimiento y de espacios verdes. Priorizamos
                mantener un feedback constante con el cliente a través del
                compromiso, la escucha activa y el asesoramiento personalizado,
                esto lo reflejamos en cada uno de los proyectos que llevamos a
                cabo, cumpliendo con las necesidades de cada uno de nuestros
                clientes a través de altos estándares de calidad. Como empresa
                nos enfocados en la preservación y el cuidado de medioambiente
                ofreciendo soluciones integrales, obteniendo así proyectos
                armónicos y responsables.
              </p>
            }
          />

          <ModalServices
            show={modalService3}
            onHide={() => setModalService3(false)}
            number={3}
            title={"Diseño"}
            description={
              <p>
                El diseño de los campos de juego como de los espacios verdes o
                parquizados, es parte fundamental de nuestra tarea. Nos
                encargamos de realizar el diseño de estos espacios de manera
                funcional y comprometidos con las exigencias del cliente, sin
                perder de vista la responsabilidad ecológica y social de nuestra
                tarea. Buscamos generar espacios que posean un diseño
                planificado y estudiado, teniendo en cuenta las inclemencias del
                clima, el terreno y todos los factores que rodean a este
                espacio.
              </p>
            }
          />
          <ModalServices
            show={modalService4}
            onHide={() => setModalService4(false)}
            number={4}
            title={"Construcción"}
            description={
              <p>
                Además del <strong>asesoramiento</strong> y
                <strong> diseño</strong>, nos encargamos de la ejecución y
                construcción del proyecto, brindando un servicio completo,
                acompañando a nuestros clientes durante todo el proceso y
                plasmando el diseño en el terreno, resolviendo paso a paso todas
                las circunstancias que puedan surgir.
              </p>
            }
          />
          <ModalServices
            show={modalService5}
            onHide={() => setModalService5(false)}
            number={5}
            title={"Mantenimiento"}
            description={
              <p>
                Realizamos mantenimiento de espacios verdes, parquizados y
                campos de juego, desde poda, siembra y riego hasta jardinería y
                monitoreo.
              </p>
            }
          />
          <ModalServices
            show={modalService6}
            onHide={() => setModalService6(false)}
            number={6}
            title={"Insumos"}
            description={
              <p>
                Brindamos a nuestros clientes un stock de insumos para el
                mantenimiento y la construcción de sus proyectos. Ofrecemos los
                mejores productos desde semillas fertilizantes y agroquímicos
                hasta máquinaria de calidad para asegurar un servicio de
                excelencia.
              </p>
            }
          />
        </>
      )}
    </>
  );
};
export default LandingScreen;
