import React from 'react'
import { TableVideo } from '../../components/Video';

const VideoScreen = () => {
    return (
        <>
            <div className="container-fluid">
                <TableVideo />
            </div>
        </>
    );
}

export default VideoScreen;
