import { useEffect, useMemo, useState } from "react";
import getVideos from "../services/Video/getVideos";
import { useLoader } from "./useLoader";

export const useVideo = () => {
  const [videos, setVideos] = useState([]);
  const [count, setCount] = useState(0);
  const { loader, setLoading } = useLoader();

  useEffect(() => {
    setLoading(true);
    getVideos()
      .then((videos) => {
        setVideos(videos.data);
        setCount(videos.count);
        setLoading(false);
      })
      .catch((error) => {
        setVideos(error);
        setLoading(false);
      });
  }, [setLoading]);

  const value = useMemo(
    () => ({
      videos,
      setVideos,
      count,
      loader,
    }),
    [videos, setVideos, loader, count]
  );

  return value;
};
