
import React, { useContext } from 'react'
import { Button, Form, Spinner } from 'react-bootstrap';
import deleteVideo from '../../services/Video/deleteVideo';
import { VideoContext } from "../../context/VideoContext";
import { useLoader } from '../../hooks/useLoader';
import { LoginContext } from '../../context/LoginContext';

const DeleteVideo = ({ closeModal }) => {

    const { selectedVideo } = useContext(VideoContext);
    const { videos, setVideos } = useContext(LoginContext);
    const { jwt } = useContext(LoginContext);

    const {
        loader,
        setLoading,
    } = useLoader();

    const removeUser = () => {
        deleteVideo({
            selectedVideo,
            videos,
            setVideos,
            closeModal,
            setLoading,
            jwt
        });
    }

    return (
        <Form
            className="container-fluid p-4 text-center"
            onSubmit={e => {
                e.preventDefault();
                removeUser();
            }}
        >
            {loader &&
                <div className="container-loader-modal">
                    <Spinner animation="grow" />
                </div>
            }
            {!loader &&
                <>
                    <Form.Group className="mb-3">
                        <Form.Label>¿Seguro que desea eliminar el video <b>{selectedVideo.name}</b>?</Form.Label>
                    </Form.Group>

                    <Button onClick={closeModal} variant="primary" type="submit" className="btn btn-danger mt-3">
                        Cancelar
                    </Button>
                    &nbsp;&nbsp;
                    <Button variant="primary" type="submit" className="btn btn-default btn-success mt-3">
                        Confirmar
                    </Button>
                </>
            }

        </Form>
    );
}

export default DeleteVideo;